import { Directive } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';

@Directive()
export abstract class QuotesToolbarBaseComponent extends QuoteFacade {
  public text = 'QuotesToolbar';

  constructor() {
    super();
  }
}
