import { Observable } from 'rxjs';
import 'reflect-metadata';
import { IOpportunityParamsPurchasePayload, IOpportunityParamsPurchaseResults } from './purchase-opportunity.interface';

/**
 * @route `opportunity`
 */
export interface IPurchaseOpportunityController {
  // private logger = new Logger(IOpportunityController.name);

  /**
   * POST
   * @route 'leads'
   * @param params
   *
   * @AccountRoles([EUserAccountTypes.ADMIN, EUserAccountTypes.CLIENT, EUserAccountTypes.CARRIER, EUserAccountTypes.REINSURER], ['super_admin'])
   */
  queryForLeads(
    params: IOpportunityParamsPurchasePayload
  ): Promise<IOpportunityParamsPurchaseResults> | Observable<IOpportunityParamsPurchaseResults>;
}
