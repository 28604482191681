import { Directive } from '@angular/core';
import { OpportunityFacade } from '../+state/opprotunity-state-base.component';

@Directive()
export abstract class OpportunityUpdateBaseComponent extends OpportunityFacade {
  public text = 'OpportunityUpdate';

  constructor() {
    super();
  }
}
