import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { Observable, switchMap } from 'rxjs';
import {
  EAccuServProQuote,
  IQuote,
  IQuoteParamsCreate,
  IQuoteParamsUpdate,
  IQuoteSearchPayload,
  IQuoteSearchResults,
  IQuoteController,
} from '@accuservpro/core/types';
import { ELPDefaultActions } from '@launchpoint/core-types';
import { environment } from '@accuservpro/xplat/core';

@Injectable()
export class QuoteV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements IQuoteController {
  //IQuoteLineItemController
  http = inject(HttpClient);
  url = '/api/asp/' + 'v1/' + EAccuServProQuote.QUOTE;

  constructor() {
    super();
    if (environment?.aspUrl && !environment?.aspUrl.endsWith('/')) {
      this.url = environment?.aspUrl + '/' + 'v1/' + EAccuServProQuote.QUOTE;
    }
    console.log({ url: this.url });
  }

  public search(params: IQuoteSearchPayload): Observable<IQuoteSearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuoteSearchResults>(this.url + '/' + ELPDefaultActions.SEARCH, params, { headers });
      })
    );
  }

  public getById(id: string): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<IQuote>(this.url + '/' + id, { headers });
      })
    );
  }

  public create(params: IQuoteParamsCreate): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuote>(this.url, params, { headers });
      })
    );
  }

  public update(params: IQuoteParamsUpdate): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IQuote>(this.url, params, { headers });
      })
    );
  }

  public delete(id: string): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<IQuote>(this.url + '/' + id, { headers });
      })
    );
  }
}
