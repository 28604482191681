// import { IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { EAccuServProQuoteProduct, IQuoteBase, IQuoteLineItem } from '../quote.interface';

// @IsString()
// dl_number: string;
// @IsBoolean()
// @IsOptional()
// included?: boolean;
// @IsObject()
// @IsOptional()
// line_item?: { [key: string]: any };
// @ValidateNested()
// @Type(() => IQuoteLineItemParamsCreateProfile)
// profile: IQuoteLineItemParamsCreateProfile;
// @IsOptional()
// @ValidateNested({ each: true })
// @Type(() => ICommercialAutoDocumentBase)
// documents?: ICommercialAutoDocumentBase[];

export class IQuoteLineItemParamsCreateOne implements Partial<IQuoteLineItem> {
  _id?: never;
  asp_product: EAccuServProQuoteProduct | undefined;
  quantity?: number | undefined;
}

export class IQuoteLineItemParamsUpdateOne implements Partial<IQuoteLineItem> {
  // @IsString()
  _id!: string;
}

export class IQuoteLineItemParamsCreate implements Pick<IQuoteBase, '_id'> {
  // @IsString()
  _id!: string;
  line_items!: IQuoteLineItemParamsCreateOne[];
}

export class IQuoteLineItemParamsUpdate implements Pick<IQuoteBase, '_id'> {
  // @IsString()
  _id!: string;
  line_item!: IQuoteLineItemParamsUpdateOne;
}

export class IQuoteLineItemParamsDelete implements Pick<IQuoteBase, '_id'> {
  // @IsString()
  _id!: string;
  // @IsString()
  line_item_id!: string;
}

export interface IQuoteLineItemController {
  add(params: IQuoteLineItemParamsCreate): Promise<IQuoteBase> | Observable<IQuoteBase>;

  update(params: IQuoteLineItemParamsUpdate): Promise<IQuoteBase> | Observable<IQuoteBase>;

  remove(params: IQuoteLineItemParamsDelete): Promise<IQuoteBase> | Observable<IQuoteBase>;
}
