import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OPPORTUNITY_EFFECTS, OPPORTUNITY_SERVICES, OpportunityFeatureKey, OpportunityReducer, UISharedModule } from '@accuservpro/xplat/features';
import { OPPORTUNITY_COMPONENTS } from './components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, UISharedModule, NgbPaginationModule];
const STATE = [StoreModule.forFeature(OpportunityFeatureKey, OpportunityReducer), EffectsModule.forFeature(OPPORTUNITY_EFFECTS)];

@NgModule({
  imports: [...MODULES, ...STATE], //
  declarations: [...OPPORTUNITY_COMPONENTS],
  exports: [...MODULES, ...OPPORTUNITY_COMPONENTS],
  providers: [...OPPORTUNITY_SERVICES],
})
export class WebOpportunityModule {}
