import { BaseComponent } from '@accuservpro/xplat/core';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as OpportunitySelectors from './selectors/opporunity.selectors';
import { firstValueFrom } from 'rxjs';
import { updateAllOpportunityPagination, updateAllOpportunityQuery, updateAllOpportunitySorting } from './actions/opportunity.actions';
import { EOpportunityStatus, EOpportunityLeadType } from '@accuservpro/core/types';

@Injectable()
export class OpportunityFacade extends BaseComponent {
  // INJECT STORE
  _Store = inject(Store);
  // ENUMS
  OpportunityStatus = EOpportunityStatus;
  OpportunityStatusValues = Object.values(EOpportunityStatus);
  OpportunityLeadType = EOpportunityLeadType;
  OpportunityLeadTypeValues = Object.values(EOpportunityLeadType);
  // All Opportunity
  opportunitys$ = this._Store.select(OpportunitySelectors.selectOpportunity);
  pagination$ = this._Store.select(OpportunitySelectors.selectOpportunityPagination); //
  success$ = this._Store.select(OpportunitySelectors.selectOpportunitySuccess);
  loading$ = this._Store.select(OpportunitySelectors.selectOpportunityLoading);
  loaded$ = this._Store.select(OpportunitySelectors.selectOpportunityLoaded);
  error$ = this._Store.select(OpportunitySelectors.selectOpportunityError);
  query$ = this._Store.select(OpportunitySelectors.selectOpportunityQuery);
  // Single Opportunity
  opportunityEnitity$ = this._Store.select(OpportunitySelectors.selectOpportunityEntity);
  opportunity$ = this._Store.select(OpportunitySelectors.selectSelectedOpportunity);
  opportunityId$ = this._Store.select(OpportunitySelectors.selectOpportunityId);
  opportunityLoading$ = this._Store.select(OpportunitySelectors.selectOpportunityLoading);
  opportunityLoaded$ = this._Store.select(OpportunitySelectors.selectOpportunityLoaded);
  // Reports
  opportunityError$ = this._Store.select(OpportunitySelectors.selectOpportunityError);
  opportunitySuccess$ = this._Store.select(OpportunitySelectors.selectOpportunitySuccess);
  // SHARED FUNCTIONS
  async updateQuery(search: string) {
    this._Store.dispatch(updateAllOpportunityQuery({ query: { search } }));
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllOpportunityPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllOpportunityPagination({ pagination: { ...pagination, limit } }));
  }
}
