import { Observable } from 'rxjs';
import { IOpportunityBase } from '../opportunity.interface';
import { IOpportunityClientData } from './client-data.interface';

export class IOpportunityClientDataParamsCreateOne implements Partial<IOpportunityClientData> {
  _id?: never;
}

export class IOpportunityClientDataParamsCreate implements Pick<IOpportunityBase, '_id'> {
  // @IsString()
  _id!: string;
  client_data!: IOpportunityClientDataParamsCreateOne[];
}

export class IOpportunityClientDataParamsUpdate implements Pick<IOpportunityBase, '_id'> {
  // @IsString()
  _id!: string;
  client_data!: IOpportunityClientData[];
}

export class IOpportunityClientDataParamsDelete implements Pick<IOpportunityBase, '_id'> {
  // @IsString()
  _id!: string;
  // @IsString()
  client_data_id!: string;
}

export interface IOpportunityClientDataController {
  add(params: IOpportunityClientDataParamsCreate): Promise<IOpportunityBase> | Observable<IOpportunityBase>;

  update(params: IOpportunityClientDataParamsUpdate): Promise<IOpportunityBase> | Observable<IOpportunityBase>;

  remove(params: IOpportunityClientDataParamsDelete): Promise<IOpportunityBase> | Observable<IOpportunityBase>;
}
