import { IQuoteSearchQuery } from '@accuservpro/core/types';
import { IHttpException, ILaunchpointAddress, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { QuoteActions } from '..';
import { QuoteEnitityState } from '../interface';

export const QuoteFeatureKey = 'QuoteReducerKey';

export interface QuoteState extends EntityState<QuoteEnitityState> {
  selected_quote_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string | null;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IQuoteSearchQuery;
  quote_address: ILaunchpointAddress | null;
}

export const QuoteEntityAdaptor: EntityAdapter<QuoteEnitityState> = createEntityAdapter<QuoteEnitityState>({
  selectId: (quote) => quote.quote_id,
});

export const initialQuoteState: QuoteState = QuoteEntityAdaptor.getInitialState({
  // set initial required properties
  selected_quote_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 10, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
  quote_address: null,
});

export const QuoteReducer = createReducer(
  initialQuoteState,

  on(QuoteActions.searchAllQuote, (state) => {
    return QuoteEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(QuoteActions.searchAllQuoteSuccess, (state, { data }) => {
    const quoteEntities = data.data.map((quote) => {
      const quoteEntity: QuoteEnitityState = {
        quote_id: quote._id,
        quote: quote,
        loaded: true,
        loading: false,
        error: null,
      };
      return quoteEntity;
    });
    return QuoteEntityAdaptor.addMany(quoteEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(QuoteActions.searchAllQuoteFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(QuoteActions.updateAllQuoteQuery, (state, { query }) => {
    return QuoteEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  // COMPANY USERS ACTIONS

  on(QuoteActions.updateAllQuotePagination, (state, { pagination }) => {
    return QuoteEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(QuoteActions.updateQuoteAddress, (state, { data }) => {
    return QuoteEntityAdaptor.removeAll({
      ...state,
      quote_address: data,
    });
  }),
  on(QuoteActions.updateAllQuotePaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(QuoteActions.updateAllQuoteSorting, (state, { querySort }) => {
    return QuoteEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(QuoteActions.getQuoteById, (state, { data_id }) => {
    const quoteEntity: QuoteEnitityState = {
      quote_id: data_id,
      quote: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return QuoteEntityAdaptor.upsertOne(quoteEntity, {
      ...state,
    });
  }),

  on(QuoteActions.getQuoteByIdSuccess, (state, { data }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          quote: data,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
        selected_quote_id: data._id,
      }
    );
  }),

  on(QuoteActions.getQuoteByIdFailure, (state, { data_id, error }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(QuoteActions.getQuoteByIdSetId, (state, { data_id }) => ({
    ...state,
    selected_quote_id: data_id,
  })),
  on(QuoteActions.createQuote, (state, { data }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(QuoteActions.createQuoteSuccess, (state, { data, success_message }) => {
    const userEntity: QuoteEnitityState = {
      quote_id: data._id,
      quote: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ? success_message : null,
    };
    return QuoteEntityAdaptor.addOne(userEntity, {
      ...state,
      selected_quote_id: data._id,
    });
  }),
  on(QuoteActions.createQuoteFailure, (state, { error }) => ({
    ...state,
    loading: true,
    error: error,
  })),

  on(QuoteActions.updateQuote, (state, { data }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(QuoteActions.updateQuoteSuccess, (state, { data, success_message }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          quote: data,
          loading: false,
          success_message,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(QuoteActions.updateQuoteFailure, (state, { data_id, error }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(QuoteActions.deleteQuote, (state, { data_id }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          quote_id: undefined,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(QuoteActions.deleteQuoteSuccess, (state, { data, success_message }) => {
    return QuoteEntityAdaptor.removeOne(data._id, state);
  }),
  on(QuoteActions.deleteQuoteFailure, (state, { data_id, error }) => {
    return QuoteEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(QuoteActions.closeQuoteSuccessMessage, (state) => ({
    ...state,
    success_message: null,
  })),
  // CLOSE ERROR MESSAGE
  on(QuoteActions.closeQuoteErrorMessage, (state) => ({
    ...state,
    // error: null,
  }))
);
