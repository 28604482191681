import { Directive } from '@angular/core';
import { OpportunityFacade } from '../+state/opprotunity-state-base.component';

@Directive()
export abstract class OpportunityModalBaseComponent extends OpportunityFacade {
  public text = 'OpportunityModal';

  constructor() {
    super();
  }
}
