import { IQueryPageination } from '@launchpoint/core-types';
import { IOpportunity } from '../opportunity.interface';

export interface IOpportunityParamsPurchase {
  num_leads: number;
  year_build: [number, number];
  postal_code: number;
  sqft: [number, number];
  exclude?: string;
  /**
   * @default 2
   */
  num_people?: number;
}

export interface IOpportunityParamsPurchasePayload {
  query: IOpportunityParamsPurchase;
  pagination: IQueryPageination;
  // querySort: IQuerySort;
}

export interface IOpportunityParamsPurchaseResults<T = Record<string, any>> {
  data: IOpportunity[];
  pagination: IQueryPageination;
}

export enum EPurchaseOpportunity {
  OPPORTUNITY = 'opportunity/purchase',
}

export enum EPurchaseOpportunityActions { // Also sometimes called resources
  LEADS = EPurchaseOpportunity.OPPORTUNITY + '/' + 'leads',
}
