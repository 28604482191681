import { Component } from '@angular/core';

import { OpportunityBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-opportunity',
  templateUrl: 'opportunity.component.html'
})
export class OpportunityComponent extends OpportunityBaseComponent {

  constructor() {
    super();
  }
}