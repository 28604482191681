<div class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div class="app-container container-xxl">
        <!--begin::Card-->
        <div class="row mb-5 ">

            <div class="col-xl-12 px-4">
                <div class="card">
                    <!--begin::Card header-->
                    <div class="card-header card-header-stretch">


                        <!--begin::Toolbar-->
                        <div class="card-toolbar m-0">
                            <!--begin::Tab nav-->
                            <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link justify-content-center text-active-gray-800 active">
                                        Open
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link justify-content-center text-active-gray-800">
                                        Won
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link justify-content-center text-active-gray-800">
                                        Lost
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link justify-content-center text-active-gray-800 text-hover-gray-800">
                                        All </a>
                                </li>
                            </ul>
                            <!--end::Tab nav-->
                        </div>
                        <!--end::Toolbar-->
                        <!--begin::Title-->
                        <div class="card-title d-flex align-items-center">
                            <input type="text" class="form-control form-control-solid w-250px ps-12"
                                placeholder="Search Leads" />
                        </div>
                        <!--end::Title-->
                    </div>
                    <!--end::Card header-->

                    <!--begin::Card body-->
                    <div class="card-body pt-0">
                        <!--begin::Table-->
                        <div id="kt_subscriptions_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div class="table-responsive">
                                @if (loading$ | async) {
                                <div class="table-loading-message">
                                    <div class="d-flex align-items-center">
                                        <div class="mr-2 text-muted">Loading...</div>
                                        <div class="spinner spinner-primary mr-10"></div>
                                    </div>
                                </div>
                                } @else {
                                <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                                    <thead>
                                        <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                            <th class="w-10px pe-2 sorting_disabled">
                                                <div
                                                    class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input class="form-check-input" type="checkbox" data-kt-check="true"
                                                        data-kt-check-target="#kt_subscriptions_table .form-check-input"
                                                        value="1" />
                                                </div>
                                            </th>
                                            <th class="min-w-125px sorting">Address</th>
                                            <th class="min-w-125px sorting">Client Name</th>
                                            <th class="min-w-125px sorting">Phone Number</th>
                                            <th class="min-w-125px sorting">Date Added</th>
                                            <!-- <th class="min-w-125px sorting">Created Date</th> -->
                                            <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1"
                                                aria-label="Actions" style="width: 153.172px">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-gray-600 fw-semibold" animateCss="fadeInDown">
                                        <tr class="odd" *ngFor="let entity of opportunitys$ | async">
                                            <td>
                                                <div
                                                    class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                </div>
                                            </td>
                                            <td>
                                                <a [routerLink]="['./' + entity.opportunity?._id]"
                                                    class="text-gray-800 text-hover-primary mb-1">{{
                                                    entity.opportunity?.address?.address_long | slice:0:75
                                                    }}</a>
                                            </td>
                                            <td>
                                                <div class="badge badge-light-success">{{ entity.opportunity?.status }}
                                                </div>
                                            </td>
                                            <td>
                                                <!-- <div class="badge badge-light">{{
                                                    entity.opportunity?.computed?.upfront?.amount_total / 100 | currency
                                                    }}
                                                </div> -->
                                            </td>
                                            <td>

                                            </td>

                                            <td class="text-end">
                                                <a href="#" class="btn btn-light btn-active-light-primary btn-sm"
                                                    data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                    Actions
                                                    <i class="ki-duotone ki-down fs-5 m-0"></i>
                                                </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                                                    data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/metronic8/demo1/../demo1/apps/subscriptions/add.html"
                                                            class="menu-link px-3"> View
                                                        </a>
                                                    </div>
                                                    <!--end::Menu item-->

                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/metronic8/demo1/../demo1/apps/subscriptions/add.html"
                                                            class="menu-link px-3"> Edit
                                                        </a>
                                                    </div>
                                                    <!--end::Menu item-->

                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" data-kt-subscriptions-table-filter="delete_row"
                                                            class="menu-link px-3"> Delete
                                                        </a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                }


                            </div>
                            <div class="row px-9 pt-3 pb-5" *ngIf="pagination$ | async as pagination">
                                <div
                                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start my-4">
                                    <div>
                                        <label><select class="form-select form-select-sm form-select-solid"
                                                [ngModel]="pagination.limit" (ngModelChange)="limit($event)">
                                                <option [value]="10">10</option>
                                                <option [value]="25">25</option>
                                                <option [value]="50">50</option>
                                                <option [value]="100">100</option>
                                            </select></label>
                                    </div>
                                </div>
                                <div
                                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end my-4">
                                    <ngb-pagination [collectionSize]="pagination.count ?? 0"
                                        [page]="pagination?.pageIndex?? 0 + 1 " [pageSize]="pagination.limit"
                                        (pageChange)="paginate($event)" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                        [boundaryLinks]="true"></ngb-pagination>
                                </div>
                            </div>
                        </div>
                        <!--end::Table-->
                    </div>
                    <!--end::Card body-->
                </div>
            </div>
        </div>
        <!--end::Card-->

        <!-- <div class="row mb-5 ">

      <div class="col-xl-12 px-4">

        <ejs-kanban #kanbanObj [keyField]="keyField" [dataSource]='quotesOnly$ | async' [cardSettings]='cardSettings'>
          <e-columns>
            @if (keyField === 'status') {
            @for (status of QuoteStatusValues; track $index) {
            <e-column [headerText]='status | uppercase' [keyField]='status'></e-column>
            }
            }
          </e-columns>
        </ejs-kanban>
      </div>
    </div> -->
    </div>
    <!--end::Content container-->
</div>