import { ICoreUser, ICoreUserAccount, ICoreUserAccountBase, ICoreUserBase, ICoreUserJWTDecorator, ICoreUserJWTToken } from '@launchpoint/core-types';
import { ILaunchpointPaymentsCustomer, ILaunchpointPaymentsModelIdsBase } from '@launchpoint/payments-types';
import 'reflect-metadata';
import { ICompany } from '../company';

export interface IUserBase extends ICoreUserBase, Pick<ILaunchpointPaymentsModelIdsBase, 'customer_id'> {
  data?: Record<string, any>;
  accounts: IUserAccountBase[];
}

export interface IUser extends ICoreUser {
  data: Record<string, any>;
  customer_id: ILaunchpointPaymentsCustomer;
  accounts: ICoreUserAccount[];
}

export enum EUserAccountType {
  ADMIN = 'Admin',
  CLIENT = 'Client',
}

export interface IUserAccountBase extends ICoreUserAccountBase {
  account_id: string;
  user_id: string;
  account_type: EUserAccountType;
}

export interface IUserAccount extends ICoreUserAccount {
  account_id: string | ICompany;
  user_id: IUser;
  account_type: EUserAccountType;
}

export type IUserJWTAccount = Pick<
  IUserAccount,
  '_id' | 'account_id' | 'account_type' | 'security_roles' | 'selected' | 'invited' | 'status' | 'user_id' | 'name'
>;

export interface IUserJWTToken extends ICoreUserJWTToken {
  accounts: IUserAccount[];
}

export interface IUserJWTDecorator extends ICoreUserJWTDecorator {
  jwt_user: IUserJWTToken;
  account?: IUserJWTAccount;
}

export type IUserJWTFlat = IUserJWTToken & IUserJWTAccount;
