import { Component } from '@angular/core';

import { QuoteCreateBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quote-create',
  templateUrl: 'quote-create.component.html',
})
export class QuoteCreateComponent extends QuoteCreateBaseComponent {
  constructor() {
    super();
  }
}
