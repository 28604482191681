import { Observable } from 'rxjs';
import 'reflect-metadata';
import { IQuote, IQuoteParamsCreate, IQuoteParamsUpdate, IQuoteSearchPayload, IQuoteSearchResults } from './quote.interface';

/**
 * @route `quote`
 */
export interface IQuoteController {
  // private logger = new Logger(CommercialAutoRateFactorsController.name);

  /**
   * POST
   * @route 'search'
   * @param params
   *
   * @AccountRoles([EUserAccountTypes.ADMIN, EUserAccountTypes.CLIENT, EUserAccountTypes.CARRIER, EUserAccountTypes.REINSURER], ['super_admin'])
   */
  search(params: IQuoteSearchPayload): Promise<IQuoteSearchResults> | Observable<IQuoteSearchResults>;

  /**
   *
   * @param id This is the _id of the rate factor to get a single item
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  getById(id: string): Promise<IQuote | null> | Observable<IQuote | null>;

  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  create(params: IQuoteParamsCreate): Promise<IQuote> | Observable<IQuote>;
  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  update(params: IQuoteParamsUpdate): Promise<IQuote> | Observable<IQuote>;
  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  delete(id: string): Promise<IQuote> | Observable<IQuote>;
}
