<div class="card ps-4 h-100" style="background-color: #2e3458">
    <div class="card-body">
        <div class="d-flex flex-row">
            <i class="fa-solid fa-house-chimney-blank fs-2 text-dark bg-white rounded p-5 me-5"></i>
            <h6 class="text-white fs-2 mt-5">Total Sales</h6>
        </div>
        <ng-container *ngIf="quote$ | async as quote">

            <div class="card mb-2 mt-5" *ngFor="let item of quote.line_items">
                <div class="card-body d-flex justify-content-between align-items-center">
                    <div>
                        <h6 class="text-muted fs-6">{{item.asp_product}}</h6>
                        <span class="fw-bold fs-1 me-10">{{item.price_data?.unit_amount_decimal | currency}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>