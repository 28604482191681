import { Observable } from 'rxjs';
import 'reflect-metadata';
import {
  IOpportunitySearchPayload,
  IOpportunity,
  IOpportunityParamsCreate,
  IOpportunityParamsUpdate,
  IOpportunitySearchResults,
} from './opportunity.interface';

/**
 * @route `opportunity`
 */
export interface IOpportunityController {
  // private logger = new Logger(IOpportunityController.name);

  /**
   * POST
   * @route 'search'
   * @param params
   *
   * @AccountRoles([EUserAccountTypes.ADMIN, EUserAccountTypes.CLIENT, EUserAccountTypes.CARRIER, EUserAccountTypes.REINSURER], ['super_admin'])
   */
  search(params: IOpportunitySearchPayload): Promise<IOpportunitySearchResults> | Observable<IOpportunitySearchResults>;

  /**
   *
   * @param id This is the _id of the rate factor to get a single item
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  getById(id: string): Promise<IOpportunity | null> | Observable<IOpportunity | null>;

  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  create(params: IOpportunityParamsCreate): Promise<IOpportunity> | Observable<IOpportunity>;
  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  update(params: IOpportunityParamsUpdate): Promise<IOpportunity> | Observable<IOpportunity>;
  /**
   *
   * @param params
   *
   * @security Account types [ADMIN,INSURED]
   * @roles ['super_admin]
   */
  delete(id: string): Promise<IOpportunity> | Observable<IOpportunity>;
}
