import { IEnvironmentApp } from '@launchpoint/core-client';

/**
 * Workspace shared environment properties
 */
export interface IEnvironment extends IEnvironmentApp {
  environmentName: 'local' | 'dev' | 'prod';
  production?: boolean;
  appVersion: string;
  USERDATA_KEY: string;
  isMockEnabled: true;
  apiUrl: string;
  aspUrl: string;
  notificationsApiUrl: string;
  appThemeName: string;
  appPurchaseUrl: string;
  appHTMLIntegration: string;
  appPreviewUrl: string;
  appPreviewAngularUrl: string;
  appPreviewDocsUrl: string;
  appPreviewChangelogUrl: string;
  // apiUrl: 'mysite.com/api',
  firebase?: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  vapidKey?: string;
}
