import { Component } from '@angular/core';

import { OpportunityDetailBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-opportunity-detail',
  templateUrl: 'opportunity-detail.component.html'
})
export class OpportunityDetailComponent extends OpportunityDetailBaseComponent {

  constructor() {
    super();
  }
}