import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
  EOpportunity,
  EPurchaseOpportunity,
  EPurchaseOpportunityActions,
  IOpportunityParamsPurchasePayload,
  IOpportunityParamsPurchaseResults,
  IPurchaseOpportunityController,
} from '@accuservpro/core/types';
import { environment } from '@accuservpro/xplat/core';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class PurchaseOpportunityV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements IPurchaseOpportunityController {
  http = inject(HttpClient);
  url = 'api/asp/' + 'v1/' + EPurchaseOpportunity.OPPORTUNITY;

  constructor() {
    super();
    if (environment?.aspUrl && !environment?.aspUrl.endsWith('/')) {
      this.url = environment?.aspUrl + '/' + 'v1/' + EOpportunity.OPPORTUNITY;
    }
  }
  queryForLeads(params: IOpportunityParamsPurchasePayload): Observable<IOpportunityParamsPurchaseResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IOpportunityParamsPurchaseResults>(this.url + '/' + EPurchaseOpportunityActions.LEADS, params, { headers });
      })
    );
  }
}
