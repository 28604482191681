import { Component } from '@angular/core';

import { QuoteEstimateBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quote-estimate',
  templateUrl: 'quote-estimate.component.html',
})
export class QuoteEstimateComponent extends QuoteEstimateBaseComponent {
  constructor() {
    super();
  }
}
