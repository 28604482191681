import { Component } from '@angular/core';

import { OpportunityUpdateBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-opportunity-update',
  templateUrl: 'opportunity-update.component.html'
})
export class OpportunityUpdateComponent extends OpportunityUpdateBaseComponent {

  constructor() {
    super();
  }
}