// import { IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EAccuServProQuoteProduct } from '../quote.interface';

// @IsString()
// dl_number: string;
// @IsBoolean()
// @IsOptional()
// included?: boolean;
// @IsObject()
// @IsOptional()
// line_item?: { [key: string]: any };
// @ValidateNested()
// @Type(() => IQuoteLineItemParamsCreateProfile)
// profile: IQuoteLineItemParamsCreateProfile;
// @IsOptional()
// @ValidateNested({ each: true })
// @Type(() => ICommercialAutoDocumentBase)
// documents?: ICommercialAutoDocumentBase[];

export interface IQuoteAggregateReportData {
  iqr_min: number;
  iqr_max: number;
  iqr_midpoint: number;
  min: number;
  max: number;
  avg: number;
  range?: number;
  size: number;
  std_dev: number;
  median?: number;
  tenth_percentile: number;
  ninetieth_percentile: number;
  correlation: number;
}
export interface IQuoteAggregateReport {
  [key: string]: IQuoteAggregateReportData;
}

export interface IQuoteAggregateReportSearchQuery {
  search?: string;
  near?: {
    coordinates: [number, number];
    minMeters?: number;
    maxMeters?: number;
  };
  product: EAccuServProQuoteProduct;
  features?: {
    data_key: string;
    normalization_method?: string;
    boundaries?: number[];
  }[];
}

export type IQuoteAggregateReportSearchPayload = IQuoteAggregateReportSearchQuery;
//  {
//   query: IQuoteAggregateReportSearchQuery;
//   pagination: IQueryPageination;
//   querySort?: IQuerySort;
// }

export type IQuoteAggregateReportSearchResults = IQuoteAggregateReport[];
// {
//   pagination: IQueryPageination;
//   data: IQuoteAggregateReport[];
// }

export interface IQuoteAggregateReportController {
  /**
   * POST
   * @route 'search'
   * @param params
   *
   * @AccountRoles([EUserAccountTypes.ADMIN, EUserAccountTypes.CLIENT, EUserAccountTypes.CARRIER, EUserAccountTypes.REINSURER], ['super_admin'])
   */
  search(params: IQuoteAggregateReportSearchQuery): Promise<IQuoteAggregateReportSearchResults> | Observable<IQuoteAggregateReportSearchResults>;
}
