import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuoteEntityAdaptor, QuoteFeatureKey, QuoteState } from '../reducer/quote.reducer';

const selectAllQuoteState = createFeatureSelector<QuoteState>(QuoteFeatureKey);

/**
 * ROOT STATE
 */
export const getAllQuoteState = createSelector(selectAllQuoteState, (state: QuoteState) => state);

export const selectQuotePagination = createSelector(getAllQuoteState, (state: QuoteState) => state.pagination);
export const selectQuoteQuerySort = createSelector(getAllQuoteState, (state: QuoteState) => state.querySort);
export const selectQuoteQuery = createSelector(getAllQuoteState, (state: QuoteState) => state.query);
export const selectQuoteLoading = createSelector(getAllQuoteState, (state: QuoteState) => state.loading);
export const selectQuoteLoaded = createSelector(getAllQuoteState, (state: QuoteState) => state.loaded);
export const selectQuoteError = createSelector(getAllQuoteState, (state: QuoteState) => state.error);
export const selectQuoteSuccess = createSelector(getAllQuoteState, (state: QuoteState) => state.success_message);

export const getAllQuoteStateEntities = createSelector(selectAllQuoteState, (state: QuoteState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = QuoteEntityAdaptor.getSelectors(selectAllQuoteState);

// select the array of ids
export const selectQuoteIds = selectIds;

// select the dictionary of entities
export const selectQuoteEntities = selectEntities;

// select the array of entities
export const selectQuotes = selectAll;

// select the total entity count
export const selectQuoteTotal = selectTotal;

export const selectQuoteAddress = createSelector(getAllQuoteState, (state: QuoteState) => state?.quote_address);

export const selectQuoteId = createSelector(getAllQuoteState, (state: QuoteState) => state?.selected_quote_id);

export const selectQuotesOnly = createSelector(getAllQuoteState, (state: QuoteState) => {
  return Object.values(state.entities).map((e) => ({
    ...e?.quote,
    title: e?.quote?.address.line_one,
    subtitle: e?.quote?.address.city,
    header: e?.quote?.lead_source,
    grabber: '#02897B',
    footer_class: 'ClassName',
  }));
});
/**
 * Entire entity, includes loading/loaded state to be used on tables and other data.
 */
export const selectQuoteEntity = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]
);
/**
 * Selected entity object, no entity data
 */
export const selectSelectedQuote = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]?.quote
);
/**
 * Selected entity loading state
 */
export const selectSelectedQuoteLoading = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]?.loading
);
/**
 * Selected entity loaded state
 * This is useful if you want to insure that while loading might not be true the data doesn't exist and an attempt was made to get it
 */
export const selectSelectedQuoteLoaded = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]?.loaded
);
/**
 * Selected entity error message
 */
export const selectSelectedQuoteError = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]?.error
);
/**
 * Selected entity success message
 */
export const selectSelectedQuoteSuccessMessage = createSelector(
  getAllQuoteState,
  selectQuoteId,
  (state: QuoteState, selected_quote_id) => state.entities[selected_quote_id]?.success_message
);
/**
 * Selected entity count for what queries are applied.
 * @default 0
 * Should be no queries at init if possible.
 */
export const selectSelectedQuoteQueryCount = createSelector(selectAllQuoteState, (state: QuoteState) => {
  let count = 0;
  try {
    // TODO: Add other query objects here
    // count = count + state.query?.ARRAY.length ?? 0;

    // query object string, if not empty | '', then count 1
    if (state.query?.search && state.query?.search.length > 0) {
      count++;
    }
  } catch (error) {
    console.log(error);
  }
  return count;
});
