import { OpportunityComponent } from './opportunity/opportunity.component';

import { OpportunitiesComponent } from './opportunities/opportunities.component';

import { OpportunityDetailComponent } from './opportunity-detail/opportunity-detail.component';

import { OpportunityModalComponent } from './opportunity-modal/opportunity-modal.component';

import { OpportunityUpdateComponent } from './opportunity-update/opportunity-update.component';

export const OPPORTUNITY_COMPONENTS = [
  OpportunityComponent,
  OpportunitiesComponent,
  OpportunityDetailComponent,
  OpportunityModalComponent,
  OpportunityUpdateComponent
];

export * from './opportunity/opportunity.component';
export * from './opportunities/opportunities.component';
export * from './opportunity-detail/opportunity-detail.component';
export * from './opportunity-modal/opportunity-modal.component';
export * from './opportunity-update/opportunity-update.component';



