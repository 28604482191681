// import { ILaunchpointPaymentsCustomer } from '@launchpoint/payments-types';
import { ICoreUser, ICoreUserAccountOwner, ICoreUserAddresses } from '@launchpoint/core-types';
import { ILaunchpointPaymentsModelIdsBase, ILaunchpointPaymentsSubscription, PAYMENTS_MODEL_POPULATE_ARRAY } from '@launchpoint/payments-types';
import { PopulateOptions } from 'mongoose';

export interface ICoreCompany {
  _id: string;
  created_at: Date;
  updated_at: Date;
  company_name: string;
  account_owner: string | ICoreUser | ICoreUserAccountOwner;
  phone: string;
  email: string;
  // status: ECompanyStatus;
  account_type: string | unknown;
  address?: ICoreUserAddresses;
}

export interface ICompanyBase extends ICoreCompany, Omit<ILaunchpointPaymentsModelIdsBase, 'customer_id'> {
  data?: Record<string, any>;
}

export interface ICompany extends ICompanyBase {
  data?: Record<string, any>;
  subscription: ILaunchpointPaymentsSubscription;
}

export const POPULATE_COMPANY: PopulateOptions[] = [
  ...PAYMENTS_MODEL_POPULATE_ARRAY,
  {
    path: 'account_owner',
  },
];
