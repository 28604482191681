import { Component } from '@angular/core';

import { OpportunityModalBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-opportunity-modal',
  templateUrl: 'opportunity-modal.component.html'
})
export class OpportunityModalComponent extends OpportunityModalBaseComponent {

  constructor() {
    super();
  }
}