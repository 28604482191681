import { Component } from '@angular/core';

import { QuoteMapBaseComponent, QuoteV1Service } from '@accuservpro/xplat/features';
import { CommonModule } from '@angular/common';
import { LngLat, Map } from 'mapbox-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@Component({
  selector: 'accuservpro-quote-map',
  templateUrl: 'quote-map.component.html',
  styleUrls: ['./quote-map.component.scss'],
  standalone: true,
  imports: [CommonModule, NgxMapboxGLModule],
  providers: [QuoteV1Service],
})
export class QuoteMapComponent extends QuoteMapBaseComponent {
  key = 'mapCenterLocationKey';

  constructor() {
    super();
  }

  override onMapReady(map: Map) {
    this.map = map;
    try {
      const test = JSON.parse(localStorage.getItem(this.key) ?? '');
      if (!test) {
        navigator.geolocation.getCurrentPosition(
          (resp) => {
            const center = new LngLat(resp.coords.longitude, resp.coords.latitude);
            this.mapCenter = [resp.coords.longitude, resp.coords.latitude];
            // console.log('SETTING', this.mapCenter);
            localStorage.setItem(this.key, JSON.stringify(this.mapCenter));

            // Center the map
            // https://docs.mapbox.com/mapbox-gl-js/api/#map#setcenter
            this.map.setCenter(center);
          },
          (err) => {
            // this.center = [-74.5, 40];
            console.log(err);
          }
        );
      } else {
        this.map.setCenter({ lat: test[0], lon: test[1] });
      }

      // JSON.parse(localStorage.getItem(this.key));
      // this.recent_searches.set(recent);
    } catch (error) {
      console.log('');
    }
  }

  changeStyle() {
    this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
  }
}
