import { Component, inject } from '@angular/core';

import { QuoteV1Service, QuotesMapBaseComponent } from '@accuservpro/xplat/features';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetsModule } from '@launchpoint/core-web';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LngLat, LngLatLike, Map, Marker } from 'mapbox-gl';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'accuservpro-quotes-map',
  templateUrl: 'quotes-map.component.html',
  styleUrls: ['./quotes-map.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, WidgetsModule, NgxMapboxGLModule, NgbModule],
  providers: [QuoteV1Service],
})
export class QuotesMapComponent extends QuotesMapBaseComponent {
  store: Store = inject(Store);

  key = 'mapCenterLocationKey';
  mapCenter: LngLatLike = [-116.387, 43.581];
  map!: Map;
  // ngOnInit(): void {}
  // customers: {
  //   location: LngLatLike;
  //   name: string;
  // }[] = [];
  myGeoJsonclusteredSource = {} as any;

  ngOnInit() {
    // this.quote_data$.pipe(takeUntil(this.destroy$)).subscribe({
    //   next: (res) => {
    //     if (this.map && res.length > 0) {
    //       res.forEach((f) => {
    //         new Marker().setLngLat(f.location.coordinates).addTo(this.map);
    //       });
    //     }
    //   },
    // });
    return;
  }

  setMap(map: Map) {
    this.map = map;
    try {
      const test = JSON.parse(localStorage.getItem(this.key) ?? '');

      // this._QuoteService
      //   .search({
      //     query: {
      //       near: {
      //         coordinates: [test[0], test[1]],
      //         minMeters: 1000 * 50,
      //         maxMeters: 1000 * 50,
      //       },
      //     },
      //     pagination: {
      //       limit: 100,
      //       skip: 0,
      //     },
      //   })
      //   .pipe(takeUntil(this.destroy$))
      //   .subscribe({
      //     next: (res) => {
      //       console.log({ res });
      //       this.customers = res.data.map((a) => ({ location: a.location as any, name: a.status }));
      //       console.log(this.customers);
      //       res.data.forEach((f) => {
      //         new Marker().setLngLat(f.location.coordinates).addTo(this.map);
      //       });
      //       // this.map.on('move', (event) => {
      //       //   console.log({ event });
      //       // });
      //     },
      //   });
      this.map.setCenter({ lat: test[0], lon: test[1] });
      // JSON.parse(localStorage.getItem(this.key));
      // const recent = JSON.parse(localStorage.getItem(this.recentkey) ?? '');
      // console.log({ recent });
      // this.recent_searches.set(recent);
    } catch (error) {
      console.log('');
    }
    navigator.geolocation.getCurrentPosition(
      (resp) => {
        const center = new LngLat(resp.coords.longitude, resp.coords.latitude);
        this.mapCenter = [resp.coords.longitude, resp.coords.latitude];
        // console.log('SETTING', this.mapCenter);
        localStorage.setItem(this.key, JSON.stringify(this.mapCenter));
        // Center the map
        // https://docs.mapbox.com/mapbox-gl-js/api/#map#setcenter
        this.map.setCenter(center);
      },
      (err) => {
        // this.center = [-74.5, 40];
        console.log(err);
      }
    );
  }
}
