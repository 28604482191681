import { Directive, OnInit } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';

@Directive()
export abstract class QuotesBaseComponent extends QuoteFacade implements OnInit {
  public text = 'Quotes';

  constructor() {
    super();
  }

  ngOnInit() {
    return;
  }
}
