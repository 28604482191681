import { Component } from '@angular/core';

import { QuoteReportingBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quote-reporting',
  templateUrl: 'quote-reporting.component.html',
})
export class QuoteReportingComponent extends QuoteReportingBaseComponent {
  constructor() {
    super();
  }
}
