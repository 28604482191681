import { Component } from '@angular/core';

import { QuotesBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quotes',
  templateUrl: 'quotes.component.html',
})
export class QuotesComponent extends QuotesBaseComponent {
  constructor() {
    super();
  }
}
