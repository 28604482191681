import { IEnvironment } from './environment.interface';

/**
 * Workspace shared environment for ease of use across all shared code and apps
 * You can ignore this file - values here only used for unit testing
 * Real Values are filled in by each runtime/platform/app
 */
export const environment: IEnvironment = {
  environmentName: 'local',
  production: false,
  mediaApiUrl: 'api/media/',
  apiUrl: 'api/backend/',
  notificationsApiUrl: 'api/notifications/',
  aspUrl: 'api/asp/',
  appVersion: 'v8.0.27',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  appThemeName: '',
  appPurchaseUrl: '',
  appHTMLIntegration: '',
  appPreviewUrl: '',
  appPreviewAngularUrl: '',
  appPreviewDocsUrl: '',
  appPreviewChangelogUrl: '',
};

setTimeout(() => {
  console.log('ENVIROMENT LOGGER');
  console.log(environment);
  console.log('ENVIROMENT LOGGER');
}, 3000);
