export enum EOpportunitySource {
  DATAFINITI = 'Datafiniti',
}

export interface IOpportunityClientData {
  _id: string; //Db created
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  source: EOpportunitySource;
}
