<div class="card py-10">
    <div class="card-body container-xxl pt-10 pb-8">
        <div class="d-flex align-items-center">
            <h1 class="fw-bold me-3">New Instant Quote</h1>
            <span class="fw-bold text-white opacity-50">New Instant Quote</span>
        </div>
        <div class="d-flex flex-column">
            <div class="d-lg-flex align-lg-items-center">
                <div
                    class="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-850px h-lg-60px me-lg-10 my-5">
                    <div class="row flex-grow-1 mb-5 mb-lg-0">
                        <div class="col-lg-8 d-flex align-items-center mb-3 mb-lg-0 border border-2 w-100 justify-content-between"
                            style="border-radius: 10px !important">
                            <input type="text" name="search" [formControl]="addressFeild"
                                placeholder="Search for address..." #receive_address_text
                                class="form-control form-control-flush flex-grow-1" />
                            <button type="button" (click)="search()" class="btn btn-primary w-200px"
                                [disabled]="!addressData()" style="margin-right: -10px !important">Run Quote</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>