import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
  EAccuServProQuote,
  IQuote,
  IQuoteLineItemController,
  IQuoteLineItemParamsCreate,
  IQuoteLineItemParamsDelete,
  IQuoteLineItemParamsUpdate,
} from '@accuservpro/core/types';
import { environment } from '@accuservpro/xplat/core';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class QuoteLineItemV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements IQuoteLineItemController {
  //
  http = inject(HttpClient);
  url = '/api/asp/' + 'v1/' + EAccuServProQuote.QUOTE_LINE_ITEM;
  constructor() {
    super();
    if (environment?.aspUrl && !environment?.aspUrl.endsWith('/')) {
      this.url = environment?.aspUrl + '/' + 'v1/' + EAccuServProQuote.QUOTE;
    }
    // console.log({ url: this.url });
  }
  add(params: IQuoteLineItemParamsCreate): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuote>(this.url, params, { headers });
      })
    );
  }

  update(params: IQuoteLineItemParamsUpdate): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuote>(this.url, params, { headers });
      })
    );
  }

  remove(params: IQuoteLineItemParamsDelete): Observable<IQuote> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuote>(this.url, params, { headers });
      })
    );
  }
}
