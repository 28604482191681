import { Directive } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';

@Directive()
export abstract class QuoteEstimateBaseComponent extends QuoteFacade {
  public text = 'QuoteEstimate';

  constructor() {
    super();
  }
}
