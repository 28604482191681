import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QUOTE_EFFECTS, QUOTE_SERVICES, QuoteFeatureKey, QuoteReducer, UISharedModule } from '@accuservpro/xplat/features';
import { QUOTE_COMPONENTS } from './components';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, UISharedModule];
const STATE = [StoreModule.forFeature(QuoteFeatureKey, QuoteReducer), EffectsModule.forFeature(QUOTE_EFFECTS)];
@NgModule({
  imports: [...MODULES, ...STATE], //
  declarations: [...QUOTE_COMPONENTS],
  exports: [...MODULES, ...QUOTE_COMPONENTS],
  providers: [...QUOTE_SERVICES],
})
export class WebQuoteModule {}
