import { Component } from '@angular/core';

import { QuoteBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quote',
  templateUrl: 'quote.component.html',
})
export class QuoteComponent extends QuoteBaseComponent {
  constructor() {
    super();
  }
}
