import { ILaunchpointAddress } from '@launchpoint/core-types';

export const HOUSING_DATA_SCHEMA = 'HOUSING_DATA_SCHEMA';

export interface IHousingData {
  _id?: string;
  buildg_sq_ft?: number;
  // geometry?: {
  //   type: string;
  //   coordinates: number[];
  // };
  built_year?: number;
  bedrooms?: number;
  baths?: number;
  partial_baths?: number;
  // mobile_home?: string;
  market_value?: number;
  sales_date?: Date;
  living_sq_ft?: number;
  buildg_features_sq_ft?: Array<{
    feature_area_sq_ft?: number;
    area_indicator?: string;
    description?: string;
  }>;
  buildg_sq_ft_source?: Array<{
    value?: number;
    code?: string;
  }>;
  num_stories: number;
  owners?: {
    owner_id?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
  }[];
  roof_cover_type?: {
    code?: string;
    value?: string;
  };
  exterior_walls?: {
    code?: string;
    value?: string;
  };
  garage_type?: {
    code?: string;
    value?: string;
  };
  last_updated?: Date;
  parking_sq_ft?: number;
  replacement_cost?: number;
  // owner_type?: string;
  address?: ILaunchpointAddress;
}
