import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OpportunityEntityAdaptor, OpportunityFeatureKey, OpportunityState } from '../reducer/opportunity.reducer';

const selectAllOpportunityState = createFeatureSelector<OpportunityState>(OpportunityFeatureKey);

/**
 * ROOT STATE
 */
export const getAllOpportunityState = createSelector(selectAllOpportunityState, (state: OpportunityState) => state);

export const selectOpportunityPagination = createSelector(getAllOpportunityState, (state: OpportunityState) => state.pagination);
export const selectOpportunityQuerySort = createSelector(getAllOpportunityState, (state: OpportunityState) => state.querySort);
export const selectOpportunityQuery = createSelector(getAllOpportunityState, (state: OpportunityState) => state.query);
export const selectOpportunityLoading = createSelector(getAllOpportunityState, (state: OpportunityState) => state.loading);
export const selectOpportunityLoaded = createSelector(getAllOpportunityState, (state: OpportunityState) => state.loaded);
export const selectOpportunityError = createSelector(getAllOpportunityState, (state: OpportunityState) => state.error);
export const selectOpportunitySuccess = createSelector(getAllOpportunityState, (state: OpportunityState) => state.success_message);

export const getAllOpportunityStateEntities = createSelector(selectAllOpportunityState, (state: OpportunityState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = OpportunityEntityAdaptor.getSelectors(selectAllOpportunityState);

// select the array of ids
export const selectOpportunityIds = selectIds;

// select the dictionary of entities
export const selectOpportunityEntities = selectEntities;

// select the array of entities
export const selectOpportunity = selectAll;

// select the total entity count
export const selectOpportunityTotal = selectTotal;

export const selectOpportunityId = createSelector(getAllOpportunityState, (state: OpportunityState) => state?.selected_opportunity_id);

/**
 * Entire entity, includes loading/loaded state to be used on tables and other data.
 */
export const selectOpportunityEntity = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]
);
/**
 * Selected entity object, no entity data
 */
export const selectSelectedOpportunity = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]?.opportunity
);
/**
 * Selected entity loading state
 */
export const selectSelectedOpportunityLoading = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]?.loading
);
/**
 * Selected entity loaded state
 * This is useful if you want to insure that while loading might not be true the data doesn't exist and an attempt was made to get it
 */
export const selectSelectedOpportunityLoaded = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]?.loaded
);
/**
 * Selected entity error message
 */
export const selectSelectedOpportunityError = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]?.error
);
/**
 * Selected entity success message
 */
export const selectSelectedOpportunitySuccessMessage = createSelector(
  getAllOpportunityState,
  selectOpportunityId,
  (state: OpportunityState, selected_opportunity) => state.entities[selected_opportunity]?.success_message
);
/**
 * Selected entity count for what queries are applied.
 * @default 0
 * Should be no queries at init if possible.
 */
export const selectSelectedOpportunityQueryCount = createSelector(selectAllOpportunityState, (state: OpportunityState) => {
  let count = 0;
  try {
    // TODO: Add other query objects here
    // count = count + state.query?.ARRAY.length ?? 0;

    // query object string, if not empty | '', then count 1
    if (state.query?.search && state.query?.search.length > 0) {
      count++;
    }
  } catch (error) {
    console.log(error);
  }
  return count;
});
