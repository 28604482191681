import { Directive, inject } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';
import { GoogleMapsService } from '../services/google-maps.service';

@Directive()
export abstract class QuoteNewSearchBaseComponent extends QuoteFacade {
  google = inject(GoogleMapsService);

  constructor() {
    super();
  }
}
