import { BaseComponent } from '@accuservpro/xplat/core';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as QuoteSelectors from './selectors/quote.selectors';
import { EAccuServProQuoteProduct, EQuoteStatus } from '@accuservpro/core/types';
import { firstValueFrom } from 'rxjs';
import { updateAllQuotePagination, updateAllQuoteQuery } from './actions/quote.actions';

@Injectable()
export class QuoteFacade extends BaseComponent {
  public _Store = inject(Store);
  QuoteStatus = EQuoteStatus;
  QuoteStatusValues = Object.values(EQuoteStatus);
  AccuServProQuoteProduct = EAccuServProQuoteProduct;
  AccuServProQuoteProductValues = Object.values(EAccuServProQuoteProduct);
  // All Quote
  quotes$ = this._Store.select(QuoteSelectors.selectQuotes);
  quotesOnly$ = this._Store.select(QuoteSelectors.selectQuotesOnly);
  selectQuoteAddress$ = this._Store.select(QuoteSelectors.selectQuoteAddress);
  pagination$ = this._Store.select(QuoteSelectors.selectQuotePagination); //
  success$ = this._Store.select(QuoteSelectors.selectQuoteSuccess);
  loading$ = this._Store.select(QuoteSelectors.selectQuoteLoading);
  loaded$ = this._Store.select(QuoteSelectors.selectQuoteLoaded);
  error$ = this._Store.select(QuoteSelectors.selectQuoteError);
  query$ = this._Store.select(QuoteSelectors.selectQuoteQuery);
  // Single Quote
  quoteEnitity$ = this._Store.select(QuoteSelectors.selectQuoteEntity);
  quote$ = this._Store.select(QuoteSelectors.selectSelectedQuote);
  quoteId$ = this._Store.select(QuoteSelectors.selectQuoteId);
  quoteLoading$ = this._Store.select(QuoteSelectors.selectQuoteLoading);
  quoteLoaded$ = this._Store.select(QuoteSelectors.selectQuoteLoaded);
  // Reports
  quoteError$ = this._Store.select(QuoteSelectors.selectQuoteError);
  quoteSuccess$ = this._Store.select(QuoteSelectors.selectQuoteSuccess);

  async updateInsuredQuery(search: string) {
    this._Store.dispatch(updateAllQuoteQuery({ query: { search } }));
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllQuotePagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllQuotePagination({ pagination: { ...pagination, limit } }));
  }
}
