import { Directive, inject } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';
import { LngLat, LngLatLike, Map } from 'mapbox-gl';
import { takeUntil } from 'rxjs';
import { GoogleMapsService } from '../services/google-maps.service';

@Directive()
export abstract class QuoteMapBaseComponent extends QuoteFacade {
  accessToken = 'pk.eyJ1Ijoicm9iZXJ0c3RhZG5pY2siLCJhIjoiY2xsb210bG5lMGFqZzNmbXdibnVtMG03aSJ9.AH2Xk8FgDZAaareqY4j1fA';
  google = inject(GoogleMapsService);
  public text = 'QuoteMap';
  mapCenter: [number, number] = [-116.387, 43.581];
  zoom: [number] = [10];
  map!: Map;

  styles = [
    { style: 'mapbox://styles/robertstadnick/cllvd0l2p006f01r6dh4j8w0s', title: 'Default' },
    { style: 'mapbox://styles/mapbox/satellite-v9', title: 'Satellite' },
  ];

  constructor() {
    super();
  }

  ngOnInit() {
    this.selectQuoteAddress$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (address) => {
        if (this.map && address) {
          console.log('ADDRESS', address);
          this.zoom = [10];
          const lon = address?.location?.coordinates[0] ?? -116.387;
          const lat = address?.location?.coordinates[1] ?? 43.581;
          console.log('LAT', lat, 'LON', lon);
          if (lon && lat) {
            this.map.setCenter({ lat, lon });
          }
          this.zoom = [17];
        }
      },
    });
  }

  onMapReady(args: any) {
    //
  }

  async addMarker(point: unknown) {}
}
