import { Injectable, inject } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as OpportunityActions from '../actions/opportunity.actions';
import * as OpportunitySelectors from '../selectors/opporunity.selectors';
import { OpportunityV1Service } from '../../services/opportuntiy.service';

@Injectable()
export class OpportunityEffects {
  private actions$ = inject(Actions);
  private _Store = inject(Store);
  private _OpportunityV1Service = inject(OpportunityV1Service);

  getAllOpportunity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        OpportunityActions.searchAllOpportunity,
        OpportunityActions.updateAllOpportunityPagination,
        OpportunityActions.updateAllOpportunityQuery,
        OpportunityActions.updateAllOpportunitySorting
      ),
      withLatestFrom(
        this._Store.select(OpportunitySelectors.selectOpportunityPagination),
        this._Store.select(OpportunitySelectors.selectOpportunityQuerySort),
        this._Store.select(OpportunitySelectors.selectOpportunityQuery)
      ),
      switchMap(([action, pagination, querySort, query]) => {
        return this._OpportunityV1Service.search({ pagination, querySort, query }).pipe(
          map((results) => {
            return OpportunityActions.searchAllOpportunitySuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(OpportunityActions.searchAllOpportunityFailure(error)))
        );
      }),
      catchError((error) => of(OpportunityActions.searchAllOpportunityFailure(error)))
    );
  });

  getOpportunityById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.getOpportunityById),
      switchMap((action) => {
        return this._OpportunityV1Service.getById(action.data_id).pipe(
          map((data) => {
            return OpportunityActions.getOpportunityByIdSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(OpportunityActions.getOpportunityByIdFailure({ data_id: action.data_id, error: error.error })))
        );
      }),
      catchError((error) => of(OpportunityActions.getOpportunityByIdFailure(error)))
    );
  });

  createOpportunityById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.createOpportunity),
      switchMap((action) => {
        return this._OpportunityV1Service.create(action.data).pipe(
          map((data) => {
            return OpportunityActions.createOpportunitySuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(OpportunityActions.createOpportunityFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(OpportunityActions.getOpportunityByIdFailure(error)))
    );
  });

  updateOpportunityById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.updateOpportunity),
      switchMap((action) => {
        return this._OpportunityV1Service.update(action.data).pipe(
          map((data) => {
            return OpportunityActions.updateOpportunitySuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(OpportunityActions.updateOpportunityFailure({ data_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(OpportunityActions.getOpportunityByIdFailure(error)))
    );
  });

  deleteOpportunityById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(OpportunityActions.deleteOpportunity),
      switchMap((action) => {
        return this._OpportunityV1Service.delete(action.data_id).pipe(
          map((data) => {
            return OpportunityActions.deleteOpportunitySuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(OpportunityActions.deleteOpportunityFailure({ data_id: action.data_id, error: error.error })))
        );
      }),
      catchError((error) => of(OpportunityActions.deleteOpportunityFailure(error)))
    );
  });
}
