/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELPDefaultActions, ILaunchpointAddress, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { ICompany } from '../company/company.interface';
import { IHousingData } from '../housing';
export const ACCU_SERV_QUOTE_SCHEMA = 'ACCU_SERV_QUOTE_SCHEMA';

/**
 * Drives queries and primary use for the quote status.
 *
 * Automation for event emails
 * Matches a stripe quote to be used for creating stripe quotes.
 */
export enum EQuoteStatus {
  /**
   * The quote can be edited while in this status and has not been sent to the customer.
   */
  DRAFT = 'draft',

  /**
   * The quote has been finalized and is awaiting action from the customer.
   */
  OPEN = 'open',

  /**
   * The customer has accepted the quote and invoice, subscription or subscription schedule has been created.
   */
  ACCEPTED = 'accepted',

  /**
   * The quote has been canceled and is no longer valid.
   */
  CANCELED = 'canceled',
}

export interface IQuoteComputed {
  upfront: {
    amount_subtotal: number;
    amount_total: number;
    total_details: {
      amount_discount: number;
      amount_shipping: number;
      amount_tax: number;
    };
  };
}

export interface IQuoteBase {
  /**
   * _id default id from database
   */
  _id: string;
  /**
   * Enum for status
   *
   * @default EQuoteStatus.DRAFT
   * @type EQuoteStatus
   * @example EQuoteStatus.DRAFT
   */
  status: EQuoteStatus;
  /**
   * Date the lead was first seen or aquired
   */
  lead_date: Date;
  /**
   * Where the lead source was. Not enum because it's customer defined
   * @example 'Google Ads'
   */
  lead_source: string;
  /**
   * Date the lead was closed
   */
  close_date: Date;
  /**
   * Uses the Launchpoint address schema to match the geo queries. Pre indexed for 2d geo.
   */
  address: ILaunchpointAddress;
  /**
   * Array of "quote" line items the customer will be charged for.
   */
  line_items: IQuoteLineItem[];
  /**
   * Computed values for the quote line itmes
   */
  computed: IQuoteComputed;
  /**
   * Id or populated housing data from the ASP core
   */
  housing_data: string | IHousingData;
  /**
   * Key value pairs for housing data from the ASP core.
   *
   * @example { "beds": 2, "baths": 1, "sqft": 1000 }
   */
  quote_data: IHousingData & Record<string, any>;
  /**
   * account_ids from Launchpoint core for the company that owns the quote and the populated value with it.
   */
  accounts: ICompany[] | string[];
}

export interface IQuote extends IQuoteBase {
  _id: string;
  status: EQuoteStatus;
  address: ILaunchpointAddress;
  line_items: IQuoteLineItem[];
  computed: IQuoteComputed;
  housing_data: IHousingData | string;
  quote_data: IHousingData & Record<string, any>;
  accounts: ICompany[];
}

export interface IQuoteParamsCreate extends Partial<Omit<IQuoteBase, '_id'>> {
  // customer: {
  //   email: string;
  //   first_name?: string;
  //   last_name?: string;
  // };
  quote_number?: string;
  address: ILaunchpointAddress;
  line_items: Pick<IQuoteLineItem, 'asp_product' | 'price_data' | 'prediction'>[];
  accounts?: string[];
}

export interface IQuoteParamsUpdate extends Partial<IQuote> {
  _id: string;
  address?: ILaunchpointAddress;
  quote_data?: IHousingData & Record<string, any>;
}

export interface IQuoteSearchQuery {
  search?: string;
  near?: {
    coordinates: [number, number];
    minMeters?: number;
    maxMeters?: number;
  };
  status?: EQuoteStatus[];
}
/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */
export interface IQuoteSearchPayload {
  query: IQuoteSearchQuery;
  pagination: IQueryPageination;
  querySort?: IQuerySort;
}

/**
 * SEARCH RESULTS
 *
 * This is the payload for the search query results.
 */
export type IQuoteData = IQuote & {
  distance: number;
  location: { type: string; coordinates: [number, number] };
};
export interface IQuoteSearchResults {
  pagination: IQueryPageination;
  data: IQuoteData[];
}

export enum EAccuServProQuote {
  QUOTE = 'quote',
  QUOTE_LINE_ITEM = 'quote-line-items',
  QUOTE_REPORTING = 'quote-reporting',
}

export enum EAccuServProQuoteActions {
  // Base Service Actions
  BASE_SEARCH = EAccuServProQuote.QUOTE + '/' + ELPDefaultActions.SEARCH,
  BASE_GET_BY_ID = EAccuServProQuote.QUOTE + '/' + ELPDefaultActions.GET_BY_ID,
  BASE_CREATE = EAccuServProQuote.QUOTE + '/' + ELPDefaultActions.CREATE,
  BASE_UPDATE = EAccuServProQuote.QUOTE + '/' + ELPDefaultActions.UPDATE,
  BASE_DELETE = EAccuServProQuote.QUOTE + '/' + ELPDefaultActions.DELETE,
  LINE_ITEM_ADD = EAccuServProQuote.QUOTE_LINE_ITEM + '/' + ELPDefaultActions.CREATE,
  LINE_ITEM_UPDATE = EAccuServProQuote.QUOTE_LINE_ITEM + '/' + ELPDefaultActions.UPDATE,
  LINE_ITEM_REMOVE = EAccuServProQuote.QUOTE_LINE_ITEM + '/' + ELPDefaultActions.DELETE,
  REPORTING_SEARCH = EAccuServProQuote.QUOTE_REPORTING + '/' + ELPDefaultActions.SEARCH,
}

/**
 * This is probably going to be temporary.
 *
 * In the future customers can create their own "products" to sell and create invoices on. For right now this will be the line item that selects what model the applciation will use the housing data to quote with.
 */
export enum EAccuServProQuoteProduct {
  ROOF_REPLACEMENT = 'Roof Replacement',
  // WINDOW_WASHING = 'Window Washing',
  // GUTTER_CLEANING = 'Gutter Cleaning',
}
export interface IQuoteLineItem {
  _id?: string;
  /**
   * This is so we know what model they want predictions on.
   */
  asp_product: EAccuServProQuoteProduct;
  /**
   * Responses from the AI Model
   */
  prediction?: {
    model: string | any; // ID OF THE MODEL/JOB
    /**
     * Predictino value in cents
     */
    prediction: number;
  };
  /**
   * Starts out as the value of the prediction but lets the user override the model's prediction
   */
  price?: string;
  /**
   * TODO: This is not ready yet. Putting Depricated just so it enphesizes it's not usable.
   * @deprecated
   */
  description?: string;
  /**
   * TODO: This is not ready yet. Putting Depricated just so it enphesizes it's not usable.
   * @deprecated
   */
  quantity?: number;

  price_data?: {
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies).
     * @default `usd`
     */
    currency?: string;

    /**
     * The ID of the product that this price will belong to.
     * TODO: require in the future for payments integration
     * @deprecated
     */
    product?: string;

    /**
     * The recurring components of a price such as `interval` and `interval_count`.
     */
    // recurring?: PriceData.Recurring;

    /**
     * Only required if a [default tax behavior](https://stripe.com/docs/tax/products-prices-tax-categories-tax-behavior#setting-a-default-tax-behavior-(recommended)) was not provided in the Stripe Tax settings. Specifies whether the price is considered inclusive of taxes or exclusive of taxes. One of `inclusive`, `exclusive`, or `unspecified`. Once specified as either `inclusive` or `exclusive`, it cannot be changed.
     */
    // tax_behavior?: PriceData.TaxBehavior;

    /**
     * A positive integer in cents (or local equivalent) (or 0 for a free price) representing how much to charge.
     *
     */
    unit_amount?: number;

    /**
     * Same as `unit_amount`, but accepts a decimal value in cents (or local equivalent) with at most 12 decimal places. Only one of `unit_amount` and `unit_amount_decimal` can be set.
     */
    unit_amount_decimal?: number;
  };
}
