import { QuoteComponent } from './quote/quote.component';

import { QuotesComponent } from './quotes/quotes.component';

import { QuoteCreateComponent } from './quote-create/quote-create.component';

import { QuotesToolbarComponent } from './quotes-toolbar/quotes-toolbar.component';

// import { QuotesMapComponent } from './quotes-map/quotes-map.component';

// import { QuoteMapComponent } from './quote-map/quote-map.component';

import { QuoteEstimateComponent } from './quote-estimate/quote-estimate.component';

import { QuoteNewSearchComponent } from './quote-new-search/quote-new-search.component';

import { QuoteReportingComponent } from './quote-reporting/quote-reporting.component';

export const QUOTE_COMPONENTS = [
  QuoteComponent,
  QuotesComponent,
  QuoteCreateComponent,
  QuotesToolbarComponent,
  // QuotesMapComponent,
  QuoteEstimateComponent,
  QuoteNewSearchComponent,
  QuoteReportingComponent,
];

export * from './quote/quote.component';
export * from './quotes/quotes.component';
export * from './quote-create/quote-create.component';
export * from './quotes-toolbar/quotes-toolbar.component';
export * from './quotes-map/quotes-map.component';
export * from './quote-map/quote-map.component';
export * from './quote-estimate/quote-estimate.component';
export * from './quote-new-search/quote-new-search.component';
export * from './quote-reporting/quote-reporting.component';
