import { Component, EventEmitter, OnInit, Output, inject, signal } from '@angular/core';

import { LaunchpointCoreWebProfileAddressAutoCompleteComponent } from '@launchpoint/core-web';
import { ELaunchpointGeo, ILaunchpointAddress } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { updateQuoteAddress } from 'libs/xplat/features/src/lib/quote/+state/actions';

@Component({
  selector: 'accuservpro-quote-new-search',
  templateUrl: 'quote-new-search.component.html',
})
export class QuoteNewSearchComponent extends LaunchpointCoreWebProfileAddressAutoCompleteComponent implements OnInit {
  addressFeild = new FormControl<string>('');
  addressData = signal<ILaunchpointAddress | null>(null);

  @Output() runQuote = new EventEmitter<ILaunchpointAddress>();
  @Output() addressSelected = new EventEmitter<ILaunchpointAddress>();

  override ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.setPlace.pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      // console.log({ lat: data.geometry?.location?.lat(), lng: data.geometry?.location?.lng() });
      const addressData = this.convertToAddressObject(data.address_components);
      addressData.location = {
        type: ELaunchpointGeo.POINT,
        coordinates: [data.geometry?.location?.lng(), data.geometry?.location?.lat()],
      };
      this.addressData.set(addressData);
      this.addressFeild.patchValue(data.formatted_address);
      if (this.addressData()) this._Store.dispatch(updateQuoteAddress({ data: addressData }));
    });
  }

  async search() {
    if (this.addressData() !== null) {
      this.runQuote.emit(this.addressData() as ILaunchpointAddress);
    }
  }

  convertToAddressObject(data: any[]): ILaunchpointAddress {
    console.log({ data });
    const addressObject: Partial<ILaunchpointAddress> = {
      address_long: '',
      country: '',
      line_one: '',
      line_two: '',
      city: '',
      state: '',
      zip_code: '',
      zip_code_ext: '',
      location: {
        type: ELaunchpointGeo.POINT,
        coordinates: [-116.387, 43.581], // This gets updated in the main service, this is just a default value so it doesn't break
      },
    };

    data.forEach((item) => {
      if (item.types.includes('street_number')) {
        addressObject.line_one += item.long_name + ' ';
      }
      if (item.types.includes('route')) {
        addressObject.line_one += item.long_name;
      }
      if (item.types.includes('subpremise')) {
        addressObject.line_two = item.long_name;
      }
      if (item.types.includes('locality')) {
        addressObject.city = item.long_name;
      }
      // if (item.types.includes('administrative_area_level_2')) { // This ended up being the county
      //   addressObject.line_two = item.long_name;
      // }
      if (item.types.includes('administrative_area_level_1')) {
        addressObject.state = item.short_name;
      }
      if (item.types.includes('country')) {
        addressObject.country = item.long_name;
      }
      if (item.types.includes('postal_code')) {
        addressObject.zip_code = item.long_name;
      }
      if (item.types.includes('postal_code_suffix')) {
        addressObject.zip_code_ext = item.long_name;
      }
    });

    addressObject.address_long = `${addressObject.line_one}, ${addressObject.city}, ${addressObject.state}, ${addressObject.zip_code}${
      addressObject.zip_code_ext ? '-' + addressObject.zip_code_ext : ''
    }`;

    return addressObject as ILaunchpointAddress;
  }
}
