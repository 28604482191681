import { IOpportunity } from '@accuservpro/core/types';
import { IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';

export interface OpportunityEnitityState {
  opportunity_id: string;
  opportunity: IOpportunity | null;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string | null;
  // ADD MORE CUSTOME PER FEATURE DATA HERE
}
