import { Component } from '@angular/core';

import { QuotesToolbarBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-quotes-toolbar',
  templateUrl: 'quotes-toolbar.component.html',
})
export class QuotesToolbarComponent extends QuotesToolbarBaseComponent {
  constructor() {
    super();
  }
}
