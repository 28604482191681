import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import {
  EAccuServProQuote,
  IQuoteAggregateReportController,
  IQuoteAggregateReportSearchQuery,
  IQuoteAggregateReportSearchResults,
} from '@accuservpro/core/types';
import { environment } from '@accuservpro/xplat/core';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class QuoteReportingV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements IQuoteAggregateReportController {
  //
  http = inject(HttpClient);
  url = '/api/asp/' + 'v1/' + EAccuServProQuote.QUOTE_REPORTING;
  constructor() {
    super();
    if (environment?.aspUrl && !environment?.aspUrl.endsWith('/')) {
      this.url = environment?.aspUrl + '/' + 'v1/' + EAccuServProQuote.QUOTE;
    }
    // console.log({ url: this.url });
  }

  search(params: IQuoteAggregateReportSearchQuery): Observable<IQuoteAggregateReportSearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQuoteAggregateReportSearchResults>(this.url + '/search', params, { headers });
      })
    );
  }
}
