import { IOpportunitySearchQuery } from '@accuservpro/core/types';
import { IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OpportunityEnitityState } from '../interface/actions.interface';
import * as OpportunityActions from '../actions/opportunity.actions';

export const OpportunityFeatureKey = 'OpportunityReducerKey';

export interface OpportunityState extends EntityState<OpportunityEnitityState> {
  selected_opportunity_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string | null;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IOpportunitySearchQuery;
}

export const OpportunityEntityAdaptor: EntityAdapter<OpportunityEnitityState> = createEntityAdapter<OpportunityEnitityState>({
  selectId: (opportunity) => opportunity.opportunity_id,
});

export const initialOpportunityState: OpportunityState = OpportunityEntityAdaptor.getInitialState({
  // set initial required properties
  selected_opportunity_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
});

export const OpportunityReducer = createReducer(
  initialOpportunityState,

  on(OpportunityActions.searchAllOpportunity, (state) => {
    return OpportunityEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(OpportunityActions.searchAllOpportunitySuccess, (state, { data }) => {
    const opportunityEntities = data.data.map((opportunity) => {
      const opportunityEntity: OpportunityEnitityState = {
        opportunity_id: opportunity._id,
        opportunity: opportunity,
        loaded: true,
        loading: false,
        error: null,
      };
      return opportunityEntity;
    });
    return OpportunityEntityAdaptor.addMany(opportunityEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(OpportunityActions.searchAllOpportunityFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(OpportunityActions.updateAllOpportunityQuery, (state, { query }) => {
    return OpportunityEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  // COMPANY USERS ACTIONS

  on(OpportunityActions.updateAllOpportunityPagination, (state, { pagination }) => {
    return OpportunityEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(OpportunityActions.updateAllOpportunityPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(OpportunityActions.updateAllOpportunitySorting, (state, { querySort }) => {
    return OpportunityEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(OpportunityActions.getOpportunityById, (state, { data_id }) => {
    const opportunityEntity: OpportunityEnitityState = {
      opportunity_id: data_id,
      opportunity: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return OpportunityEntityAdaptor.upsertOne(opportunityEntity, {
      ...state,
    });
  }),

  on(OpportunityActions.getOpportunityByIdSuccess, (state, { data }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          opportunity: data,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(OpportunityActions.getOpportunityByIdFailure, (state, { data_id, error }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(OpportunityActions.getOpportunityByIdSetId, (state, { data_id }) => ({
    ...state,
    selected_opportunity_id: data_id,
  })),
  on(OpportunityActions.createOpportunity, (state, { data }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(OpportunityActions.createOpportunitySuccess, (state, { data, success_message }) => {
    const userEntity: OpportunityEnitityState = {
      opportunity_id: data._id,
      opportunity: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ? success_message : null,
    };
    return OpportunityEntityAdaptor.addOne(userEntity, {
      ...state,
    });
  }),
  on(OpportunityActions.createOpportunityFailure, (state, { error }) => ({
    ...state,
    loading: true,
    error: error,
  })),

  on(OpportunityActions.updateOpportunity, (state, { data }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(OpportunityActions.updateOpportunitySuccess, (state, { data, success_message }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          opportunity: data,
          loading: false,
          success_message,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(OpportunityActions.updateOpportunityFailure, (state, { data_id, error }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(OpportunityActions.deleteOpportunity, (state, { data_id }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          opportunity_id: undefined,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(OpportunityActions.deleteOpportunitySuccess, (state, { data, success_message }) => {
    return OpportunityEntityAdaptor.removeOne(data._id, state);
  }),
  on(OpportunityActions.deleteOpportunityFailure, (state, { data_id, error }) => {
    return OpportunityEntityAdaptor.updateOne(
      {
        id: data_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(OpportunityActions.closeOpportunitySuccessMessage, (state) => ({
    ...state,
    success_message: null,
  })),
  // CLOSE ERROR MESSAGE
  on(OpportunityActions.closeOpportunityErrorMessage, (state) => ({
    ...state,
    // error: null,
  }))
);
