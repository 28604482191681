import { ELPDefaultActions, ILaunchpointActions, ILaunchpointAddress, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { ICompany } from '../company/company.interface';
import { IHousingData } from '../housing/housing-data.interface';
import { IOpportunityClientData } from './client-data/client-data.interface';

export const ACCU_SERV_OPPORTUNITY_SCHEMA = 'ACCU_SERV_OPPORTUNITY_SCHEMA';

export enum EOpportunityStatus {
  OPEN = 'Open',
  WON = 'Won',
  LOST = 'Lost',
}
export enum EOpportunityLeadType {
  HOT = 'Hot',
  WARM = 'Warm',
  COLD = 'Cold',
  FREEZING = 'Freezing',
}

export interface IOpportunityBase {
  /**
   * _id default id from database
   */
  _id: string;
  /**
   * Date the lead was first seen or aquired
   */
  added_date?: Date;
  /**
   * Date the lead was closed
   */
  close_date?: Date;
  /**
   * Enum for status
   *
   * @default EOpportunityStatus.OPEN
   * @type EOpportunityStatus
   * @example EOpportunityStatus.OPEN
   */
  status?: EOpportunityStatus;
  /**
   * level of opportunity warm to cold
   */
  opportunity_type?: EOpportunityLeadType;
  /**
   * Where the lead source was. Not enum because it's customer defined
   * @example 'Google Ads'
   */
  lead_source: string;
  /**
   * client of the opportunity and their contact info
   */
  client_data: IOpportunityClientData[];
  /**
   * Uses the Launchpoint address schema to match the geo queries. Pre indexed for 2d geo.
   */
  address: ILaunchpointAddress;
  /**
   * Id or populated housing data from the ASP core
   */
  housing_data: string | IHousingData;
  /**
   * Key value pairs for housing data from the ASP core.
   *
   * @example { "beds": 2, "baths": 1, "sqft": 1000 }
   */
  quote_data: IHousingData & Record<string, any>;
  /**
   * account_ids from Launchpoint core for the company that owns the quote and the populated value with it.
   */
  accounts: ICompany[] | string[];
}
/**
 * Base represents what' goes into the database Non base is what FE sees/gets
 *
 * generic and optionally takes in a T to add to the quote_data field
 *
 */
export interface IOpportunity extends IOpportunityBase {
  housing_data: IHousingData;
  quote_data: IHousingData & Record<string, any>;
  accounts: ICompany[];
}

export enum EOpportunity { // These equal the controllers
  OPPORTUNITY = 'opportunity',
}

export enum EOpportunityActions { // Also sometimes called resources
  // Base Service Actions
  BASE_SEARCH = EOpportunity.OPPORTUNITY + '/' + ELPDefaultActions.SEARCH,
  BASE_GET_BY_ID = EOpportunity.OPPORTUNITY + '/' + ELPDefaultActions.GET_BY_ID,
  BASE_CREATE = EOpportunity.OPPORTUNITY + '/' + ELPDefaultActions.CREATE,
  BASE_UPDATE = EOpportunity.OPPORTUNITY + '/' + ELPDefaultActions.UPDATE,
  BASE_DELETE = EOpportunity.OPPORTUNITY + '/' + ELPDefaultActions.DELETE,
  BASE_ARCHIVE = EOpportunity.OPPORTUNITY + '/' + 'ARCHIVE',
}

export interface IOpportunityParamsCreate extends Partial<Omit<IOpportunityBase, '_id'>> {
  // _id: never;
  added_date: Date;
}

export interface IOpportunityParamsUpdate extends Partial<Omit<IOpportunityBase, 'address' | 'housing_data' | 'accounts'>> {
  _id: string;
  added_date: Date;
}

export interface IOpportunitySearchQuery {
  search?: string;
  near?: {
    coordinates: [number, number];
    minMeters?: number;
    maxMeters?: number;
  };
  status?: EOpportunityStatus[];
  opportunity_type?: EOpportunityLeadType[];
}
/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */
export interface IOpportunitySearchPayload {
  query: IOpportunitySearchQuery;
  pagination: IQueryPageination;
  querySort?: IQuerySort;
}

export interface IOpportunitySearchResults {
  pagination: IQueryPageination;
  data: IOpportunityData[];
}

/**
 * SEARCH RESULTS
 *
 * This is the payload for the search query results.
 */
export type IOpportunityData = IOpportunity & {
  distance: number;
  location: { type: string; coordinates: [number, number] };
};

export type IOpportunityActionsJSON = ILaunchpointActions<EOpportunityActions>;

export const OpportunityActionsJSON: IOpportunityActionsJSON[] = [
  {
    action: EOpportunityActions.BASE_UPDATE,
    title: 'Open',
    conditions: { status: EOpportunityStatus.LOST },
    active: false,
  },
  //   {
  //     action: EOpportunityActions.BASE_ARCHIVE,
  //     title: 'Remove',
  //     conditions: { status: EOpportunityStatus.LOST },
  //     active: false,
  //   },
  //   {
  //     action: EOpportunityActions.BASE_UPDATE,
  //     title: 'Open',
  //     conditions: { status: EOpportunityStatus.LOST },
  //     active: false,
  //   },
];
