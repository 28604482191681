import { Directive } from '@angular/core';
import { QuoteFacade } from '../+state/quote.facade';

@Directive()
export abstract class QuotesMapBaseComponent extends QuoteFacade {
  public text = 'QuotesMap';

  constructor() {
    super();
  }

  queryAllQuotes() {
    // this._state.getQuotes([-116.387, 43.581]);
  }
}
