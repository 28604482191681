import {
  IOpportunitySearchResults,
  IOpportunitySearchQuery,
  IOpportunity,
  IOpportunityParamsCreate,
  IOpportunityParamsUpdate,
} from '@accuservpro/core/types';
import { IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllOpportunity = createAction('[f-Opportunity] searchAllOpportunity');
export const searchAllOpportunitySuccess = createAction(
  '[f-Opportunity] searchAllOpportunitySuccess',
  props<{ data: IOpportunitySearchResults; success_message?: string }>()
);

export const searchAllOpportunityFailure = createAction('[f-Opportunity] searchAllOpportunityFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllOpportunityPagination = createAction(
  '[f-Opportunity] updateAllOpportunityPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllOpportunityPaginationScroll = createAction(
  '[f-Opportunity] updateAllOpportunityPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllOpportunitySorting = createAction('[f-Opportunity] updateAllOpportunitySorting', props<{ querySort: IQuerySort }>());
export const updateAllOpportunityQuery = createAction('[f-Opportunity] updateAllOpportunityQuery', props<{ query: IOpportunitySearchQuery }>());

// getById
export const getOpportunityById = createAction('[f-Opportunity] getOpportunityById', props<{ data_id: string }>());
export const getOpportunityByIdSuccess = createAction(
  '[f-Opportunity] getOpportunityByIdSuccess',
  props<{ data: IOpportunity; success_message?: string }>()
);
export const getOpportunityByIdFailure = createAction(
  '[f-Opportunity] getOpportunityByIdFailure',
  props<{ data_id: string; error: IHttpException }>()
);

export const getOpportunityByIdSetId = createAction('[f-Opportunity] getOpportunityByIdSetId', props<{ data_id: string }>());

// CREATE
export const createOpportunity = createAction('[f-Opportunity] createOpportunity', props<{ data: IOpportunityParamsCreate }>());
export const createOpportunitySuccess = createAction(
  '[f-Opportunity] createOpportunitySuccess',
  props<{ data: IOpportunity; success_message?: string }>()
);
export const createOpportunityFailure = createAction('[f-Opportunity] createOpportunityFailure', props<{ error: IHttpException }>());

// UPDATE
export const updateOpportunity = createAction('[f-Opportunity] updateOpportunity', props<{ data: IOpportunityParamsUpdate }>());
export const updateOpportunitySuccess = createAction(
  '[f-Opportunity] updateOpportunitySuccess',
  props<{ data: IOpportunity; success_message?: string }>()
);
export const updateOpportunityFailure = createAction('[f-Opportunity] updateOpportunityFailure', props<{ data_id: string; error: IHttpException }>());

// DEACTIVATE
export const deactivateOpportunity = createAction('[f-Opportunity] deactivateOpportunity', props<{ data: IOpportunityParamsUpdate }>());

// DELETE
export const deleteOpportunity = createAction('[f-Opportunity] deleteOpportunity', props<{ data_id: string }>());
export const deleteOpportunitySuccess = createAction(
  '[f-Opportunity] deleteOpportunitySuccess',
  props<{ data: IOpportunity; success_message?: string }>()
);
export const deleteOpportunityFailure = createAction('[f-Opportunity] deleteOpportunityFailure', props<{ data_id: string; error: IHttpException }>());

/**
 * CLOSE ALERT MESSAGES
 */
export const closeOpportunitySuccessMessage = createAction('[f-Opportunity] closeOpportunitySuccessMessage');

export const closeOpportunityErrorMessage = createAction('[f-Opportunity] closeOpportunityErrorMessage');
