import { Component } from '@angular/core';

import { OpportunitiesBaseComponent } from '@accuservpro/xplat/features';

@Component({
  selector: 'accuservpro-opportunities',
  templateUrl: 'opportunities.component.html'
})
export class OpportunitiesComponent extends OpportunitiesBaseComponent {

  constructor() {
    super();
  }
}