import { IQuoteSearchResults, IQuoteSearchQuery, IQuote, IQuoteParamsCreate, IQuoteParamsUpdate } from '@accuservpro/core/types';
import { IHttpException, ILaunchpointAddress, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllQuote = createAction('[f-Quote] searchAllQuote');
export const searchAllQuoteSuccess = createAction(
  '[f-Quote] searchAllQuoteSuccess',
  props<{ data: IQuoteSearchResults; success_message?: string }>()
);

export const searchAllQuoteFailure = createAction('[f-Quote] searchAllQuoteFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllQuotePagination = createAction('[f-Quote] updateAllQuotePagination', props<{ pagination: IQueryPageination }>());
export const updateAllQuotePaginationScroll = createAction('[f-Quote] updateAllQuotePaginationScroll', props<{ pagination: IQueryPageination }>());
export const updateAllQuoteSorting = createAction('[f-Quote] updateAllQuoteSorting', props<{ querySort: IQuerySort }>());
export const updateAllQuoteQuery = createAction('[f-Quote] updateAllQuoteQuery', props<{ query: IQuoteSearchQuery }>());

// getById
export const getQuoteById = createAction('[f-Quote] getQuoteById', props<{ data_id: string }>());
export const getQuoteByIdSuccess = createAction('[f-Quote] getQuoteByIdSuccess', props<{ data: IQuote; success_message?: string }>());
export const getQuoteByIdFailure = createAction('[f-Quote] getQuoteByIdFailure', props<{ data_id: string; error: IHttpException }>());

export const getQuoteByIdSetId = createAction('[f-Quote] getQuoteByIdSetId', props<{ data_id: string }>());

// CREATE
export const createQuote = createAction('[f-Quote] createQuote', props<{ data: IQuoteParamsCreate }>());
export const createQuoteSuccess = createAction('[f-Quote] createQuoteSuccess', props<{ data: IQuote; success_message?: string }>());
export const createQuoteFailure = createAction('[f-Quote] createQuoteFailure', props<{ error: IHttpException }>());

// UPDATE
export const updateQuote = createAction('[f-Quote] updateQuote', props<{ data: IQuoteParamsUpdate }>());
export const updateQuoteSuccess = createAction('[f-Quote] updateQuoteSuccess', props<{ data: IQuote; success_message?: string }>());
export const updateQuoteFailure = createAction('[f-Quote] updateQuoteFailure', props<{ data_id: string; error: IHttpException }>());

// updateQuoteAddress
export const updateQuoteAddress = createAction('[f-Quote] updateQuoteAddress', props<{ data: ILaunchpointAddress }>());

// DELETE
export const deleteQuote = createAction('[f-Quote] deleteQuote', props<{ data_id: string }>());
export const deleteQuoteSuccess = createAction('[f-Quote] deleteQuoteSuccess', props<{ data: IQuote; success_message?: string }>());
export const deleteQuoteFailure = createAction('[f-Quote] deleteQuoteFailure', props<{ data_id: string; error: IHttpException }>());

/**
 * CLOSE ALERT MESSAGES
 */
export const closeQuoteSuccessMessage = createAction('[f-Quote] closeQuoteSuccessMessage');

export const closeQuoteErrorMessage = createAction('[f-Quote] closeQuoteErrorMessage');
