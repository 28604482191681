import { Directive } from '@angular/core';
import { OpportunityFacade } from '../+state/opprotunity-state-base.component';

@Directive()
export abstract class OpportunitiesBaseComponent extends OpportunityFacade {
  public text = 'Opportunities';

  constructor() {
    super();
  }
}
