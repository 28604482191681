import { OpportunityV1Service } from './opportunity.service';
import { PurchaseOpportunityV1Service } from './purchase-opportunity.service';

export const OPPORTUNITY_SERVICES = [
  //
  OpportunityV1Service,
  PurchaseOpportunityV1Service,
];

export * from './opportunity.service';
export * from './purchase-opportunity.service';
