import { Injectable, inject } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as QuoteActions from '../actions/quote.actions';
import * as QuoteSelectors from '../selectors/quote.selectors';
import { QuoteV1Service } from '../../services/quote.service';

@Injectable()
export class QuoteEffects {
  private actions$ = inject(Actions);
  private _Store = inject(Store);
  private _QuoteV1Service = inject(QuoteV1Service);

  getAllQuote$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        QuoteActions.searchAllQuote,
        QuoteActions.updateAllQuotePagination,
        QuoteActions.updateAllQuoteQuery,
        QuoteActions.updateAllQuoteSorting
      ),
      withLatestFrom(
        this._Store.select(QuoteSelectors.selectQuotePagination),
        this._Store.select(QuoteSelectors.selectQuoteQuerySort),
        this._Store.select(QuoteSelectors.selectQuoteQuery)
      ),
      switchMap(([action, pagination, querySort, query]) => {
        console.log('Getting updateAllQuoteQuery');
        return this._QuoteV1Service.search({ pagination, querySort, query }).pipe(
          map((results) => {
            return QuoteActions.searchAllQuoteSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(QuoteActions.searchAllQuoteFailure(error)))
        );
      }),
      catchError((error) => of(QuoteActions.searchAllQuoteFailure(error)))
    );
  });

  getQuoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuoteActions.getQuoteById),
      switchMap((action) => {
        return this._QuoteV1Service.getById(action.data_id).pipe(
          map((data) => {
            return QuoteActions.getQuoteByIdSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(QuoteActions.getQuoteByIdFailure({ data_id: action.data_id, error: error.error })))
        );
      }),
      catchError((error) => of(QuoteActions.getQuoteByIdFailure(error)))
    );
  });

  createQuoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuoteActions.createQuote),
      switchMap((action) => {
        return this._QuoteV1Service.create(action.data).pipe(
          map((data) => {
            return QuoteActions.createQuoteSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(QuoteActions.createQuoteFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(QuoteActions.getQuoteByIdFailure(error)))
    );
  });

  updateQuoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuoteActions.updateQuote),
      switchMap((action) => {
        return this._QuoteV1Service.update(action.data).pipe(
          map((data) => {
            return QuoteActions.updateQuoteSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(QuoteActions.updateQuoteFailure({ data_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(QuoteActions.getQuoteByIdFailure(error)))
    );
  });

  deleteQuoteById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuoteActions.deleteQuote),
      switchMap((action) => {
        return this._QuoteV1Service.delete(action.data_id).pipe(
          map((data) => {
            return QuoteActions.deleteQuoteSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(QuoteActions.deleteQuoteFailure({ data_id: action.data_id, error: error.error })))
        );
      }),
      catchError((error) => of(QuoteActions.deleteQuoteFailure(error)))
    );
  });
}
