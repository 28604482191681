import {
  ELPDefaultActions,
  ICoreCompany,
  ICoreUser,
  ICoreUserAccount,
  ILaunchpointAddress,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { ICompany } from '../company/company.interface';
export const ACCU_SERV_JOB_SCHEMA = 'ACCU_SERV_JOB_SCHEMA';

export interface ITeamMember {
  position: 'Sales Rep' | 'Sales Manager';
  team_member: string | ICoreUser;
}

export interface IMeasurement {
  square?: number;
  pitch?: string;
  waste_percentage?: string;
  eve_length?: string;
  break_edge?: string;
  hip_length?: string;
  ridge?: string;
  ridge_vent?: string;
  valley?: string;
  gutters?: string;
  flashing?: string;
  step_flashing?: string;
  two_story?: string;
  flat_squares?: string;
  flat_perimeter?: string;
}

export interface ICreatedBy {
  user: string | ICoreUser;
  full_name: string;
}

export enum EJobStatus {
  ESTIMATING = 'Estimating',
  QUOTING = 'Quoting',
  PRE_PRODUCTION = 'Pre Production',
  PRODUCTION = 'Production',
  POST_PRODUCTION = 'Post Production',
  COMPLETE = 'Complete',
}

export interface IJobBase {
  _id: string;
  // customers: string | ICoreUser[];
  // measurements: IMeasurement;
  status: EJobStatus;
  // job_number?: string;
  addresses: ILaunchpointAddress[];
  // team_members: ITeamMember[];
  // created_by: ICreatedBy;
  // bid_type: 'Cash' | 'Insurance';
  // claim_number?: string;
  accounts: ICompany[];
}

export interface IJob extends IJobBase {
  _id: string;
  // customers: ICoreUser[];
  // measurements: IMeasurement;
  status: EJobStatus;
  // job_number?: string;
  addresses: ILaunchpointAddress[];
  // team_members: ITeamMember[];
  // created_by: ICreatedBy;
  // bid_type: 'Cash' | 'Insurance';
  // claim_number?: string;
  accounts: ICompany[];
}

export interface IJobParamsCreate extends Partial<Omit<IJob, '_id'>> {
  customer: {
    email: string;
    first_name?: string;
    last_name?: string;
  };
  addresses: ILaunchpointAddress[];
  bid_type?: 'Cash' | 'Insurance';
}

export interface IJobParamsUpdate extends Partial<IJob> {
  _id: string;
  addresses?: ILaunchpointAddress[];
  bid_type?: 'Cash' | 'Insurance';
}
export interface IJobSearchQuery {
  search?: string;
  near?: {
    coordinates: [number, number];
    minMeters?: number;
    maxMeters?: number;
  };
}
/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */
export interface IJobSearchPayload {
  query: IJobSearchQuery;
  pagination: IQueryPageination;
  querySort?: IQuerySort;
}

/**
 * SEARCH RESULTS
 *
 * This is the payload for the search query results.
 */
export type IJobData = IJob & {
  distance: number;
  location: { type: string; coordinates: [number, number] };
};
export interface IJobSearchResults {
  pagination: IQueryPageination;
  data: IJobData[];
}

export enum EAccuServProJob {
  JOB = 'job',
}

export enum EAccuServProJobActions {
  // Base Service Actions
  BASE_SEARCH = EAccuServProJob.JOB + '/' + ELPDefaultActions.SEARCH,
  BASE_GET_BY_ID = EAccuServProJob.JOB + '/' + ELPDefaultActions.GET_BY_ID,
  BASE_CREATE = EAccuServProJob.JOB + '/' + ELPDefaultActions.CREATE,
  BASE_UPDATE = EAccuServProJob.JOB + '/' + ELPDefaultActions.UPDATE,
  BASE_DELETE = EAccuServProJob.JOB + '/' + ELPDefaultActions.DELETE,
}
