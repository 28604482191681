import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '@launchpoint/core-client';
import { Observable, switchMap } from 'rxjs';
import { ELPDefaultActions } from '@launchpoint/core-types';
import { environment } from '@accuservpro/xplat/core';
import {
  EOpportunity,
  IOpportunity,
  IOpportunityController,
  IOpportunityParamsCreate,
  IOpportunityParamsUpdate,
  IOpportunitySearchPayload,
  IOpportunitySearchResults,
} from '@accuservpro/core/types';

@Injectable()
export class OpportunityV1Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements IOpportunityController {
  http = inject(HttpClient);
  url = 'api/asp/' + 'v1/' + EOpportunity.OPPORTUNITY;

  constructor() {
    super();
    if (environment?.aspUrl && !environment?.aspUrl.endsWith('/')) {
      this.url = environment?.aspUrl + '/' + 'v1/' + EOpportunity.OPPORTUNITY;
    }
  }

  public search(params: IOpportunitySearchPayload): Observable<IOpportunitySearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IOpportunitySearchResults>(this.url + '/' + ELPDefaultActions.SEARCH, params, { headers });
      })
    );
  }

  public getById(id: string): Observable<IOpportunity> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<IOpportunity>(this.url + '/' + id, { headers });
      })
    );
  }

  public create(params: IOpportunityParamsCreate): Observable<IOpportunity> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IOpportunity>(this.url, params, { headers });
      })
    );
  }

  public update(params: IOpportunityParamsUpdate): Observable<IOpportunity> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IOpportunity>(this.url, params, { headers });
      })
    );
  }

  public delete(id: string): Observable<IOpportunity> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<IOpportunity>(this.url + '/' + id, { headers });
      })
    );
  }
}
