/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { ELaunchpointGeo, ILaunchpointAddress } from '@launchpoint/core-types';
import { firstValueFrom, map, of } from 'rxjs';

interface MatchedSubstring {
  length: number;
  offset: number;
}

interface StructuredFormatting {
  main_text: string;
  main_text_matched_substrings: MatchedSubstring[];
  secondary_text: string;
}

interface Term {
  offset: number;
  value: string;
}

export interface AddressObject {
  description?: string;
  matched_substrings?: MatchedSubstring[];
  place_id?: string;
  reference?: string;
  structured_formatting?: StructuredFormatting;
  terms?: Term[];
  types?: string[];
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  northeast: Location;
  southwest: Location;
}

interface Geometry {
  location: Location;
  viewport: Viewport;
}

interface OpeningHourPeriod {
  close: { day: number; time: string };
  open: { day: number; time: string };
}

interface OpeningHours {
  open_now: boolean;
  periods: OpeningHourPeriod[];
  weekday_text: string[];
}

interface Photo {
  height: number;
  html_attributions: string[];
  photo_reference: string;
  width: number;
}

interface Review {
  author_name: string;
  author_url: string;
  language: string;
  profile_photo_url: string;
  rating: number;
  relative_time_description: string;
  text: string;
  time: number;
}

interface Result {
  address_components: AddressComponent[];
  adr_address: string;
  business_status: string;
  formatted_address: string;
  formatted_phone_number: string;
  geometry: Geometry;
  icon: string;
  icon_background_color: string;
  icon_mask_base_uri: string;
  international_phone_number: string;
  name: string;
  opening_hours: OpeningHours;
  photos: Photo[];
  place_id: string;
  plus_code: {
    compound_code: string;
    global_code: string;
  };
  rating: number;
  reference: string;
  reviews: Review[];
  types: string[];
  url: string;
  user_ratings_total: number;
  utc_offset: number;
  vicinity: string;
  website: string;
}

interface ApiResponse {
  html_attributions: any[];
  result: Result;
  status: string;
}

interface ApiSearchResponse {
  plus_code: any;
  results: Result[];
}

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
  constructor() {}
  key = 'AIzaSyBVkNd0v6TUZI_uCZczOwnL3QqHcxnsZF0';
  autoCompleteUrl = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=';
  autoCompleteUrl2 = '&key=' + this.key;

  urlReq = 'https://maps.googleapis.com/maps/api/place/details/json?place_id=';
  urlreq2 = '&key=' + this.key;

  urlSearchReq = 'https://maps.googleapis.com/maps/api/geocode/json?';
  urlSearchReq2 = '&key=' + this.key;

  searchResults = signal<AddressObject[]>([]);
  http = inject(HttpClient);

  auto(typed: string | null) {
    if (typed) {
      // console.log('got to func', typed);
      return this.http
        .get<{ predictions: AddressObject[] }>(this.autoCompleteUrl + typed + this.autoCompleteUrl2)
        .pipe(map((data: { predictions: AddressObject[] }) => data.predictions));
    } else {
      return of(null);
    }
  }

  async findPlace(place: any): Promise<ApiResponse> {
    // return this.http.get(this.urlReq+place+this.urlreq2);
    try {
      return await firstValueFrom(this.http.get<ApiResponse>(this.urlReq + place + this.urlreq2));
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async searchPlace(lat: number, lng: number): Promise<ApiSearchResponse> {
    const search = `latlng=${lat},${lng}`;
    console.log({ search });
    try {
      console.log({ search: this.urlSearchReq + search + this.urlSearchReq2 });
      const data = await firstValueFrom(this.http.get<ApiSearchResponse>(this.urlSearchReq + search + this.urlSearchReq2));
      console.log(JSON.stringify(data.results[0].address_components));
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async convertToAddressObject(data: {
    address_components: AddressComponent[];
    geometry: { location: { lng: number; lat: number } };
  }): Promise<ILaunchpointAddress> {
    try {
      const addressObject: Partial<ILaunchpointAddress> = {
        address_long: '',
        country: '',
        line_one: '',
        line_two: '',
        city: '',
        state: '',
        zip_code: '',
        zip_code_ext: '',
        location: {
          type: ELaunchpointGeo.POINT,
          coordinates: [data.geometry?.location?.lng, data.geometry?.location?.lat],
        },
      };

      data.address_components.forEach((item) => {
        if (item.types.includes('street_number')) {
          addressObject.line_one += item.long_name + ' ';
        }
        if (item.types.includes('route')) {
          addressObject.line_one += item.long_name;
        }
        if (item.types.includes('subpremise')) {
          addressObject.line_two = item.long_name;
        }
        if (item.types.includes('locality')) {
          addressObject.city = item.long_name;
        }
        // if (item.types.includes('administrative_area_level_2')) { // This ended up being the county
        //   addressObject.line_two = item.long_name;
        // }
        if (item.types.includes('administrative_area_level_1')) {
          addressObject.state = item.short_name;
        }
        if (item.types.includes('country')) {
          addressObject.country = item.long_name;
        }
        if (item.types.includes('postal_code')) {
          addressObject.zip_code = item.long_name;
        }
        if (item.types.includes('postal_code_suffix')) {
          addressObject.zip_code_ext = item.long_name;
        }
      });

      addressObject.address_long = `${addressObject.line_one}, ${addressObject.city}, ${addressObject.state}, ${addressObject.zip_code}${
        addressObject.zip_code_ext ? '-' + addressObject.zip_code_ext : ''
      }`;

      return addressObject as ILaunchpointAddress;
    } catch (error) {
      console.log(error);
      throw new Error(error as any);
    }
  }
}
